import React, { FC } from "react"
import marked from "marked"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Img, { ImgProps } from "../components/Img"
import { PageProps } from "../types"
import Layout from "../components/Layout"
import Slideshow from "../components/Slideshow"

interface ProjectPageTemplateProps {
  html: string,
  title: string,
  gallery: {
    caption: string,
    image: ImgProps
  }[]
}

const ProjectPageTemplate: FC<ProjectPageTemplateProps> = ({
  html,
  title,
  gallery
}) => {
  return (
    <main
      id="prosjekt-page"
      className="page md:flex md:flex-col-reverse"
    >
      <section
        id="intro"
        className="page-section py-24 lg:py-16"
      >
        <div className="page-section-text-wrapper max-w-2xl mb-0">
          <h1 className="text-5xl h2 lg:text-3xl">
            {title}
          </h1>

          <hr
            className="sep sep-v mx-auto"
          />

          <div
            className="markdown cc-2 text-left"
            dangerouslySetInnerHTML={{
              __html: marked(html)
            }}
          />
        </div>
      </section>

      {gallery?.length && (
        <section
          id="galleri"
          className="page-section px-0 pt-0 md:pb-0"
        >
          <div className="mx-auto w-full max-w-10xl">
            <Slideshow
              slidesPerView={1}
              navigation={true}
              pagination={{
                clickable: true
              }}
            >
              {gallery.map(({
                caption,
                image: {
                  src,
                  alt
                }
              }, index) => (
                <figure
                  className="relative"
                  key={index}
                >
                  <Img
                    className="max-h-screen"
                    src={src}
                    alt={alt}
                  />

                  <figcaption
                    className="absolute px-4 py-1 bottom-0 right-0"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.66)"
                    }}
                  >
                    {caption}
                  </figcaption>
                </figure>
              ))}
            </Slideshow>
          </div>
        </section>
      )}
    </main>
  )
}

interface ProjectPageProps {
  data: {
    markdownRemark: {
      frontmatter: ProjectPageTemplateProps & PageProps,
      html: string
    }
  }
}

const ProjectPage: React.FC<ProjectPageProps> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
        image={frontmatter.meta.image}
      />

      <ProjectPageTemplate
        html={html}
        title={frontmatter.title}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

export default ProjectPage

export const ProjectPageQuery = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {

        meta {
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100 maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        title

        gallery {
          caption
          image {
            src {
              childImageSharp {
                fluid(quality: 100 maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }

      }
    }
  }
`
